
import { defineComponent, ref } from "vue";
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import BackTop from "@/components/BackTop.vue";
import ChatBox from "@/components/productsBox.vue";
import CookieBox from "@/components/CookieBox.vue";
import { usePinia } from "@/store";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: "Home",
  components: {
    TopBar,
    Footer,
    BackTop,
    ChatBox,
    CookieBox,
  },
  metaInfo: {
    title: "B2B Marketplace - Chemicals & Steel - OTIMES",
    meta: [
      {
        name: "description",
        content:
          "OTIMES  B2B Marketplace for chemicals and Steel Provide lower prices, instant quote service,thousands of suppliers and customers. Main markets are Southeast Asia and Europe(Singapore,Vietnam,Hong Kong,Thailand,Malaysia etc.)",
      },
      {
        name: "keywords",
        content:
          "OTIMES,PLA,PC,POM,PA,ABS,PP,Engineering Plastics,PLASTIC MATERIALS,STEEL,Cold Rolled Steel,Silicon Steel",
      },
    ],
  },
  setup() {
    const store = usePinia();
    const { active } = storeToRefs(store) as any;
    const videoTag = ref(true);
    const buttonTag = ref(1);
    const moreTag = ref(1);
    const historyMoreTag = ref(1);
    const setActiveName = (name) => {
      active.value = name;
    };
    return {
      setActiveName,
      videoTag,
      buttonTag,
      moreTag,
      historyMoreTag,
    };
  },
});
