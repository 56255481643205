import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home.html",
  },
  {
    path: "/home.html",
    name: "Home",
    component: Home,
  },
  {
    path: "/about.html",
    name: "ABOUT US",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/history.html",
    name: "HISTORY",
    component: () => import("../views/history.vue"),
  },
  {
    path: "/partners.html",
    name: "PARTNERS",
    component: () => import("../views/partners.vue"),
  },
  {
    path: "/career.html",
    name: "CAREER",
    component: () => import("../views/career.vue"),
  },
  {
    path: "/cookie.html",
    name: "COOKIE POLICY",
    component: () => import("../views/cookie.vue"),
  },
  {
    path: "/service.html",
    name: "OUR SERVICE",
    component: () => import("../views/service.vue"),
  },
  {
    path: "/products",
    name: "PRODUCTS",
    redirect: "/products/products-index.html",
    component: () => import("../views/products.vue"),
    children: [
      {
        path: "products-index.html",
        name: "products-index",
        component: () => import("../views/products/index.vue"),
      },
      {
        path: "similar-goods.html",
        name: "similar-goods",
        component: () => import("../views/products/similarGoods.vue"),
      },
      {
        path: "similar-detail.html",
        name: "similar-detail",
        component: () => import("../views/products/detail.vue"),
      },
    ],
  },
  {
    path: "/global.html",
    name: "GLOBAL PRESENCE",
    component: () => import("../views/global.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   const store = usePinia();
  //   const { scrollY } = storeToRefs(store);
  //   if (to.path.includes("products")) {
  //     if (savedPosition) {
  //       return savedPosition;
  //     } else {
  //       return { top: scrollY.value };
  //     }
  //   }
  // },
});
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  // window.pageYOffset = 0;
  window.scrollTo(0, 0);
  next();
});
export default router;
