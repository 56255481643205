import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import axios from "axios";
import VueAxios from "vue-axios";
import MetaInfo from "vue-meta-info";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import VueLazyload from "vue-lazyload";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";
import vue3GoogleLogin from "vue3-google-login";

const pinia = createPinia();
pinia.use(piniaPluginPersist);

const app = createApp(App);
app.use(vue3GoogleLogin, {
  clientId:
    "811017753903-o064g7ebvu326i64u4dbgt94co789kuj.apps.googleusercontent.com",
});

app.use(VueLazyload);
app.use(ElementPlus);
app.use(VueAxios, axios);
app.use(pinia).use(router).use(MetaInfo).mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
