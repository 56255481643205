
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Footer",
  setup() {
    const contactTag = ref(1);
    const linkTag = ref(0);

    return {
      linkTag,
      contactTag,
    };
  },
});
