
import { defineComponent, onMounted, ref, watch } from 'vue';
import { usePinia } from '@/store';
import { storeToRefs } from 'pinia'
import { sendMessage, maxChatUi, setUserMsg, showChatUi, onAssignAgent, onChatMaximized, onChatMinimized } from '@/utils/liveChat';
import { googleTokenLogin } from "vue3-google-login"
import axios from 'axios';
import Cookie from 'js-cookie'
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'ChatBox',
  setup () {
    const store = usePinia()
    const { shopList, showShop, showShopBt, sendState, sendCount } = storeToRefs(store) as any
    const showSupplier = ref(false)
    const chatTag = ref(false)
    const showCode = ref(false)
    const text = ref('')
    const url = ref('')
    const getUrl = () => {
      window.open(`https://wa.me/8615070949098?text=${text.value}`)
    }

    const fixedBox: any = ref()
    const shopRef: any = ref()
    const shopRefBt: any = ref()
    onMounted(() => {
      document.body.addEventListener('click', event => {
        if (!(fixedBox.value && fixedBox.value.contains(event.target))) {
          showCode.value = false
          chatTag.value = false
        }
      })
    })

    const activeTag = ref(2)
    const toggle = type => {
      activeTag.value = type
      showShop.value = false
      if (type === 1) {
        showCode.value = false
        chatTag.value = false
      } else if (type === 2) {
        showCode.value = false
        showSupplier.value = false
      } else {
        showSupplier.value = false
        chatTag.value = false
      }
    }

    watch(shopList, (n, o) => {
      sendState.value = true
      sendCount.value = 0
    }, {
      deep: true
    })

    const send = () => {
      const params = { shop: [] };
      params.shop = shopList.value.map((v) => {
        return {
          title: v.title,
          img: v.allImg.split(",")[1],
          href: `${location.protocol}${location.host}/products/similar-detail.html?id=${v.id}&category=${v.category}&essentialDetailsKey=${v.essentialDetailsKey}`,
        };
      });
      if (sendState.value) {
        if (shopList.value.length) {
          sendState.value = false
          sendMessage(params)
          if (sendCount.value < 1) {
            ElMessage.success('Repeated sending of product links！')
          }
          sendCount.value++
        }
      } else {
        if (shopList.value.length) {
          ElMessage.error('Repeated sending of product links！')
        }
      }
      maxChatUi()
    }

    const clickTalk = ref(false)
    onChatMaximized(() => {
      const name = Cookie.get('name')
      const email = Cookie.get('email')
      if (name && email) { !clickTalk.value && setUserMsg({ email, name }); } else {
        googleLogin()
      }
    })

    onAssignAgent(() => {
      sendState.value = true
      send()
    })

    const googleLogin = () => {
      googleTokenLogin().then((response) => {
        const { access_token } = response;
        axios.request({
          method: 'get',
          url: 'https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses',
          headers: { 'Authorization': `Bearer ${access_token}` }
        }).then(res => {
          const email = res.data.emailAddresses[0].value
          const name = res.data.names[0].displayName
          Cookie.set('email', email, { expires: 100 })
          Cookie.set('name', name, { expires: 100 })
          setUserMsg({ email, name })
          send()
        })
      })
    }

    //显示对话框
    const showTalk = () => {
      clickTalk.value = true;
      showShop.value = false
      const name = Cookie.get('name')
      const email = Cookie.get('email')
      if (name && email) {
        setUserMsg({ email, name })
        send()
      } else {
        googleLogin()
      }
    }

    const clickShop = () => {
      showShop.value = !showShop.value
      showCode.value = false
      showSupplier.value = false
      chatTag.value = false
    }

    //移除商品
    const remove = item => {
      const index = shopList.value.findIndex(v => v.id === item.id)
      shopList.value.splice(index, 1)
      if (!shopList.value.length) {
        showShop.value = false
      }
    }

    //轮询图片分割 
    const splitImg = (imgs): any => {
      try {
        const list = imgs.split(',')
        return list[1]
      } catch (err) {
        console.log('分割图片出错了');
      }
    }

    return {
      shopRefBt,
      shopRef,
      fixedBox,
      showTalk,
      clickShop,
      remove,
      splitImg,
      shopList,
      chatTag,
      text,
      url,
      getUrl,
      activeTag,
      toggle,
      showShop,
      showCode,
      showShopBt,
      showSupplier
    }
  }
});
