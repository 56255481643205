
import { defineComponent, ref } from 'vue';
import { usePinia } from "@/store";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: 'TopBar',
  setup () {
    const store = usePinia()
    const { active, menuName } = storeToRefs(store) as any
    const select = name => {
      if (name === '/products') {
        active.value = 'PLASTICS MATERIAL';
        menuName.value = ''
      }
    }
    return {
      select
    }
  }
});
