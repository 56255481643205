
import { defineComponent, ref } from 'vue'
import { setToken, getToken, delToken } from '@/utils/unit'
export default defineComponent({
  name: 'CookieBox',
  setup() {
    const cookieTag = ref(1)
    const setCookie = () => {
      setToken('cookieValue', 365)
    }
    const getCookie = () => {
      const cookieValue = getToken()
      if(cookieValue){
        cookieTag.value = 0
      }else{
        cookieTag.value = 1
      }
    }
    getCookie()
    // delToken()
    return{
      cookieTag,
      setCookie,
    }
  },
})
