const _window = window as any;

export const sendMessage = (params) => {
  const chat = document.getElementById("rocketchat-iframe") as any;
  if (chat) {
    chat.contentWindow.postMessage(JSON.stringify(params), "*");
  }
};

export const setUserMsg = ({ email, name }) => {
  const chat = document.getElementById("rocketchat-iframe") as any;
  const params = { userMsg: { userEmail: email, userName: name } };
  if (chat) {
    chat.contentWindow.postMessage(JSON.stringify(params), "*");
  }
};

export const showChatUi = () => {
  _window.RocketChat(function () {
    this.showWidget();
  });
};

export const onChatMaximized = (callback) => {
  _window.RocketChat(function () {
    this.onChatMaximized(function () {
      callback();
    });
  });
};

export const onChatMinimized = (callback) => {
  _window.RocketChat(function () {
    this.onChatMinimized(function () {
      callback();
    });
  });
};

export const onAssignAgent = (callback) => {
  _window.RocketChat(function () {
    this.onAssignAgent(function (data) {
      callback();
    });
  });
};

export const hideChatUi = () => {
  _window.RocketChat(function () {
    this.hideWidget();
  });
};

export const maxChatUi = () => {
  _window.RocketChat(function () {
    this.maximizeWidget();
  });
};

export const minChatUi = () => {
  _window.RocketChat(function () {
    this.minimizeWidget();
  });
};

export const onChatEnded = (callback) => {
  _window.RocketChat(function () {
    this.onChatEnded(function () {
      callback();
    });
  });
};
