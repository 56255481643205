import { defineStore } from "pinia";

export const usePinia = defineStore("storeId", {
  state: () => {
    return {
      active: "PLASTICS MATERIAL", //塑料还是钢材
      menuName: "", //菜单
      showShopBt: true, //是否显示商店按钮
      showShop: false, //是否显示商店
      shopList: [], //添加到购物车的商品
      detail: null, //商品详情
      scrollY: 0,
      sendState: true,
      sendCount: 0,
    };
  },
  persist: {
    //这里存储默认使用的是session
    enabled: true,
    strategies: [
      {
        //key的名称
        key: "jc-storeId",
        //更改默认存储，我更改为localStorage
        storage: sessionStorage,
        // 可以选择哪些进入local存储，这样就不用全部都进去存储了
        // 默认是全部进去存储
        paths: [
          "active",
          "showShopBt",
          "showShop",
          "shopList",
          "detail",
          "scrollY",
          "sendState",
          "sendCount",
        ],
      },
    ],
  },
  actions: {},
  getters: {},
});
